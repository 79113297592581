import * as React from 'react'
//import { Link } from "gatsby"
import Layout from '/src/components/layout'
//import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import { faLanguage, faPlug, faQuestionCircle, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons'

import CountKwhPricePerMin from '/src/components/countKwhPricePerMin'

const KostnaderPage = () => {

    return (
        <Layout pageTitle="Kostnader för att ladda elbil">

            <p class="intro">Till skillnad från fossila bränslen varierar priset för laddning stort mellan olika alternativ. Med kunskap och planering kan du optimera dina kostnader.</p>

            <h2>Prismodeller</h2>
            <ul>
                <li><b>Per kWh</b> innebär att du betalar för mängden el (kWh) du laddar, oavsett tid. 10 kWh under en timme eller ett dygn kostar lika mycket.</li>
                <li><b>Per minut</b> innebär att du betalar för tiden du laddar, oavsett hur mycket eller hur lite du får laddat (antal kWh).</li>
            </ul>

            <h2>Räkna ut kostnad</h2>
            <ul>
                <li><b>Pris per mil</b> räknas ut genom att multiplicera kostnaden per kWh med förbrukningen, antal kWh per mil. Exempelvis 2 kr/kWh * 2 kWh/mil = 4 kr/mil.</li>
                <li><b>Pris per kWh</b> kan variera stort beroende på hur du laddar och oftast betalar du per kWh. Vid betalning per minut kan det förenklat omvandlas till kr/kWh genom att multiplicera kr/min med 60 och sedan divideras med effekten i kW. Exempelvis 4 kr/min * 60 / 50 kW = 4,8 kr/kWh. Detta är dock förutsatt att effekten är just 50 kW hela timmen, effekten i kalkylen bör därför vara lägre.</li>
            </ul>

            <CountKwhPricePerMin />


            <h2>Trängselavgift</h2>
            <p>Det är inte helt ovanligt att laddnätverk tillämpar en avgift för de som tar upp en laddplats utan att behöva den. Detta kan ske på olika sätt, exempelvis genom att laddning de första 45 minutrarna kostar per kWh och därefter både per kWh och per minut. Eller att det tillkommer en trängsel-/straffavgift när bilen är färdigladdad.</p>

            <h2>Vad kostar en "full tank"?</h2>
            <p>Till att börja med är ofta elbilsvardagen annorlunda, 0-100 % tankning är för dinosauriebilar, försök att ladda ofta när bilen ändå står stilla så behöver du till vardags aldrig köra &amp; tanka. För att besvara frågan; är ditt batteri på 50 kWh och du laddar för 2 kr/kWh blir kostnaden för att ladda fullt 100 kr (50*2). Skulle du istället snabbladda och betala exempelvis 5 kr/kWh blir kostnaden 250 kr (50*5).</p>
            
        </Layout>
    )
}

export default KostnaderPage